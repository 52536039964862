import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';

// import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import { Observer } from 'gsap/Observer';
// import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// import { TextPlugin } from 'gsap/TextPlugin';
// import { InertiaPlugin } from 'gsap/InertiaPlugin';
import { SplitText } from 'gsap/SplitText';
import { ScrambleTextPlugin } from 'gsap/ScrambleTextPlugin';
// import { ScrollSmoother } from 'gsap/ScrollSmoother';
// import { Flip } from 'gsap/Flip';

// gsap.registerPlugin(ScrollTrigger, SplitText, ScrambleTextPlugin);
gsap.registerPlugin(CustomEase, SplitText, ScrambleTextPlugin);
// gsap.registerPlugin(SplitText);
// gsap.registerPlugin(ScrollTrigger, Observer, ScrollToPlugin, TextPlugin, InertiaPlugin, SplitText, Flip);
// gsap.config({ force3D: true, autoSleep: 2400 });
// gsap.config({ force3D: true, autoSleep: 2400 });
CustomEase.create('cubic', '.43,.17,.38,1');
gsap.defaults({ ease: 'cubic' });

// export {
//     gsap, ScrollTrigger, Observer, ScrollToPlugin, TextPlugin, InertiaPlugin, SplitText, Flip,
// };
export { gsap, SplitText, CustomEase };
