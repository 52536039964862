<script setup>
import XClose from '~/assets/icons/X-close.svg';
import TriangleRouge from '~/assets/icons/triangle_rouge.svg';
import LogoAzimutReverse from '~/assets/icons/Logo_Azimut_RGB.svg';
import CaretDown from '~/assets/icons/caret-down.svg';

const {
    navOpened,
    subnavOpened,
    // countryPanelIsVisible,
} = useClientState();

// const {
// navs, sites, alternateUrls, activeSite,
// } = useSharedState();
const {
    // navs, activeSite,
    navs,
} = useSharedState();

const mobileSubmenuOpened = ref(null);
const itemsLength = ref(0);
itemsLength.value = (navs?.value?.main?.length ?? 0) + (navs?.value?.secondary?.length ?? 0);

const makeTransitionListEnterCumul = (el, done) => {
    transitionListEnterCumul(el, itemsLength.value, done);
};
</script>
<template>
    <Transition
        v-if="navs?.main?.length"
        enter-active-class="duration-1000"
        enter-from-class="!w-0"
        enter-to-class="!w-screen"
        leave-active-class="duration-1000"
        leave-from-class="!w-screen"
        leave-to-class="!w-0"
        v-on:enter="subnavOpened = true"
        v-on:leave="subnavOpened = false"
    >
        <nav
            v-show="navOpened"
            class="nav-overlay backface-hidden fixed right-0 top-0 z-60 min-h-screen w-screen overflow-hidden bg-perle pt-0 transition-all"
        >
            <div class="absolute right-0 top-0 z-10 flex w-full justify-between border-black px-7 py-5 lg:h-full lg:w-24 lg:border-l lg:p-0">
                <MainLink to="/" class="absolute right-[calc(100vw-1.75rem)] translate-x-full lg:hidden">
                    <LogoAzimutReverse class="h-auto w-36 xs:w-45" />
                </MainLink>
                <button class="absolute right-9 top-7 z-10 lg:relative lg:left-[50%] lg:top-11 lg:flex lg:-translate-x-1/2" v-on:click.prevent="navOpened = !navOpened">
                    <XClose class="fill-current" />
                </button>
                <MainLink to="/" class="absolute bottom-7 left-[50%] hidden -translate-x-1/2 lg:block">
                    <TriangleRouge />
                </MainLink>
            </div>
            <Transition
                enter-active-class="duration-1000"
                enter-from-class="translate-x-0"
                enter-to-class="translate-x-0"
                leave-active-class="duration-1000 delay-1000"
                leave-from-class="translate-x-0"
                leave-to-class="translate-x-1"
            >
                <!-- delay guarantees no animation on close -->
                <div v-show="navOpened" class="absolute right-0 top-0 h-screen w-screen text-black transition lg:pr-24">
                    <div
                        v-if="navs?.socials"
                        class="relative hidden lg:block"
                    >
                        <TransitionGroup
                            tag="ul"
                            :css="false"
                            class="absolute flex w-full justify-end gap-4 px-11 pt-12 text-right"
                            v-on:enter="makeTransitionListEnterCumul"
                            v-on:leave="transitionListLeaveEmpty"
                        >
                            <li
                                v-for="(link, index) in navs.socials"
                                v-show="subnavOpened"
                                :key="link.id"
                                :data-index="index + navs.main.length"
                                class="mb-1"
                            >
                                <a
                                    :href="link.uri"
                                    class="inline-block fill-current transition hover:text-carmin"
                                    target="_blank"
                                    v-html="link.icon.inline"
                                />
                            </li>
                        </TransitionGroup>
                    </div>
                    <div class="flex h-screen flex-col items-center justify-between px-7 pb-7 pt-24 text-right lg:h-full lg:flex-row lg:flex-wrap lg:px-11 lg:pt-31">
                        <div class="mb-4 flex w-full grow items-center justify-end uppercase lg:items-start">
                            <TransitionGroup
                                tag="ul"
                                :css="false"
                                class="text-5xl font-light uppercase lg:text-6xl"
                                v-on:enter="makeTransitionListEnterCumul"
                                v-on:leave="transitionListLeaveEmpty"
                            >
                                <li
                                    v-for="(item, index) in navs.main"
                                    v-show="subnavOpened"
                                    :key="item.id"
                                    class="mb-6"
                                    :data-index="index"
                                >
                                    <MainLink
                                        :to="item.uri"
                                        :class="{'text-gold': mobileSubmenuOpened === item.id}"
                                        class="border-b-[0.135em] border-transparent transition hover:!border-carmin"
                                        active-class="!border-carmin"
                                        v-on:click.prevent="mobileSubmenuOpened = mobileSubmenuOpened === item.id ? null : item.id"
                                    >
                                        {{ item.title }}

                                        <CaretDown
                                            v-if="item.children"
                                            class="ml-1.5 inline-block h-auto w-3 fill-current transition duration-1000"
                                            :class="{'-rotate-180':mobileSubmenuOpened === item.id}"
                                        />
                                    </MainLink>
                                    <ul
                                        v-if="item.children"
                                        v-show="mobileSubmenuOpened === item.id"
                                    >
                                        <li
                                            v-for="(child, indexChild) in item.children"
                                            :key="indexChild"
                                        >
                                            <MainLink
                                                :to="child.uri"
                                                class="border-b-[0.135em] border-transparent transition hover:!border-carmin"
                                                active-class="!border-carmin"
                                            >
                                                {{ child.title }}
                                            </MainLink>
                                        </li>
                                    </ul>
                                </li>
                            </TransitionGroup>
                        </div>

                        <div class="flex w-full grow-0 flex-wrap items-end justify-between lg:flex-nowrap">
                            <MainLink to="/" class="hidden lg:block">
                                <LogoAzimutReverse />
                            </MainLink>
                            <div class="relative lg:hidden">
                                <TransitionGroup
                                    tag="ul"
                                    :css="false"
                                    class="flex w-full gap-4"
                                    v-on:enter="makeTransitionListEnterCumul"
                                    v-on:leave="transitionListLeaveEmpty"
                                >
                                    <li
                                        v-for="(link, index) in navs.socials"
                                        :key="link.id"
                                        :data-index="index + navs.socials.length"
                                        class="mb-1"
                                    >
                                        <a
                                            :href="link.uri"
                                            class="inline-block fill-current transition hover:text-carmin"
                                            target="_blank"
                                            v-html="link.icon.inline"
                                        />
                                    </li>
                                </TransitionGroup>
                            </div>
                            <div class="flex w-full items-baseline justify-between text-xxs xs:text-xs lg:w-auto lg:items-center lg:text-base">
                                <TransitionGroup
                                    tag="ul"
                                    :css="false"
                                    class="flex lg:justify-end"
                                    v-on:enter="makeTransitionListEnterCumul"
                                    v-on:leave="transitionListLeaveEmpty"
                                >
                                    <li
                                        v-for="(item, index) in navs.secondary"
                                        v-show="subnavOpened"
                                        :key="item.id"
                                        :data-index="index + navs.main.length"
                                        class="mb-1 ml-3 border-l border-current pl-3 leading-none first:ml-0 first:border-none first:pl-0"
                                    >
                                        <MainLink
                                            :to="item.uri"
                                            class="border-b-[0.135em] border-transparent transition hover:!border-carmin"
                                            active-class="!border-carmin"
                                        >
                                            {{ item.title }}
                                        </MainLink>
                                    </li>
                                </TransitionGroup>
                                <MainLink to="/" class="lg:hidden">
                                    <TriangleRouge class="h-auto w-4 xs:w-8 sm:w-auto" />
                                </MainLink>
                            </div>
                        </div>
                    </div>
                </div>
            </Transition>

            <!--<Transition
                enter-active-class="duration-1000 delay-200"
                enter-from-class="translate-y-full"
                enter-to-class="translate-y-0"
                leave-active-class="duration-1000"
                leave-from-class="translate-y-0"
                leave-to-class="translate-y-full"
            >
                <div v-show="subnavOpened" class="bg-gold text-midblue -mt-132 rounded-t-4xl pb-120 pl-8 pr-4 pt-8 uppercase">
                    <div
                        v-for="(item, index) in navs.secondary"
                        :key="item.id"
                        :data-index="index"
                    >
                        <Transition
                            :css="false"
                            v-on:enter="makeTransitionListEnterCumul"
                            v-on:leave="transitionListLeaveEmpty"
                        >
                            <div
                                v-show="subnavOpened"
                                :key="'subsubnav'+1"
                                :data-index="navs.main.length + navs.secondary.length + 2"
                                class="text-lg"
                            >
                                {{ item.title }}
                            </div>
                        </Transition>
                        <TransitionGroup
                            v-if="item.children"
                            tag="ul"
                            :css="false"
                            class="grid grid-cols-auto-sm gap-x-3 gap-y-1 py-6 text-sm"
                            v-on:enter="makeTransitionListEnterCumul"
                            v-on:leave="transitionListLeaveEmpty"
                        >
                            <li
                                v-for="(child, indexChild) in item.children"
                                v-show="subnavOpened"
                                :key="'subsubnav'+1+indexChild"
                                :data-index="navs.main.length + navs.secondary.length + 2"
                                class="whitespace-nowrap"
                            >
                                <MainLink
                                    :to="child.uri"
                                >
                                    <span class="border-gold mr-2 inline-block rounded-full border-2 bg-white text-center">
                                        <ArrowLine class="fill-gold inline-block max-w-[40%]" />
                                    </span>
                                    {{ child.title }}
                                </MainLink>
                            </li>
                        </TransitionGroup>
                    </div>
                </div>
            </Transition>-->
        </nav>
    </Transition>
</template>
