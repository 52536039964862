import { default as _91_46_46_46slug_938x11OgsRwoMeta } from "/Users/dee/Projets/Jef/Azimut/04_final/azimut.test/nuxt/pages/[...slug].vue?macro=true";
import { default as index7L6L0EhR91Meta } from "/Users/dee/Projets/Jef/Azimut/04_final/azimut.test/nuxt/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93yy8Az391m1Meta } from "/Users/dee/Projets/Jef/Azimut/04_final/azimut.test/nuxt/pages/realisations/[...slug].vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_938x11OgsRwoMeta?.name ?? "slug___fr-ca",
    path: _91_46_46_46slug_938x11OgsRwoMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_938x11OgsRwoMeta || {},
    alias: _91_46_46_46slug_938x11OgsRwoMeta?.alias || [],
    redirect: _91_46_46_46slug_938x11OgsRwoMeta?.redirect,
    component: () => import("/Users/dee/Projets/Jef/Azimut/04_final/azimut.test/nuxt/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: index7L6L0EhR91Meta?.name ?? "index___fr-ca",
    path: index7L6L0EhR91Meta?.path ?? "/",
    meta: index7L6L0EhR91Meta || {},
    alias: index7L6L0EhR91Meta?.alias || [],
    redirect: index7L6L0EhR91Meta?.redirect,
    component: () => import("/Users/dee/Projets/Jef/Azimut/04_final/azimut.test/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93yy8Az391m1Meta?.name ?? "realisations-slug___fr-ca",
    path: _91_46_46_46slug_93yy8Az391m1Meta?.path ?? "/realisations/:slug(.*)*",
    meta: _91_46_46_46slug_93yy8Az391m1Meta || {},
    alias: _91_46_46_46slug_93yy8Az391m1Meta?.alias || [],
    redirect: _91_46_46_46slug_93yy8Az391m1Meta?.redirect,
    component: () => import("/Users/dee/Projets/Jef/Azimut/04_final/azimut.test/nuxt/pages/realisations/[...slug].vue").then(m => m.default || m)
  }
]